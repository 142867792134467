import getcsvFileKeys from "./entity.utils.js"
const sidebar = false;


const keys = [
  {
    dataTableID: "company_id",
    tooltip: "Company ID of this record",
    label: "Company ID",
    columnType: "number",
    table: {
      displayOrder: 1
    },
    filter: {
      //chipTitle: "academy_id ~= $input",
      displayOrder: 1,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: "company_summary_reports/by",
        fetchQuery: {
          $where: {
            $or: {
              company_id: {
                $ilike: "%$input%"
              },
              company_name: {
                $ilike: "%$input%"
              }
            }
          }
        },
        label: ['company_id', 'company_name'],
        value: 'company_id',
        labelSeperator: ' - ',
      },
      filterQuery: {
        $where: {
          company_id: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "company_name",
    tooltip: "Name of Company",
    label: "Company Name",
    columnType: "string",
    create: {
      displayOrder: 1
    },
    table: {
      displayOrder: 2,
      columnType: "string",
    },
    filter: {
      displayOrder: 2
    }
  },
  {
    dataTableID: "company_sector",
    tooltip: "Sector of Company",
    label: "Company Sector",
    columnType: "number",
    create: {
      displayOrder: 2
    },
    table: {
      displayOrder: 3,
      columnType: "number",
    },
    filter: {
      displayOrder: 3
    }
  },
  // {
  //   dataTableID: "company_users_data",
  //   tooltip: "Company User's Data",
  //   label: "Data of Company User",
  //   columnType: "string",
  //   create: {
  //     displayOrder: 3
  //   },
  //   table: {
  //     displayOrder: 4,
  //     columnType: "array[map]",
  //     display: ["email"],
  //     onHoverDisplay: "*",
  //     displaySeprator: ", "
  //   },
  //   filter: {
  //     displayOrder: 4
  //   }
  // },
  {
    dataTableID: "user_details",
    tooltip: "Detail of the user",
    label: "User Details",
    columnType: "string",
    create: {
      displayOrder: 4
    },
    table: {
      displayOrder: 5,
      columnType: "map",
      display: ["email"],
      onHoverDisplay: "*",
      displaySeprator: ", "
    },
    filter: {
      displayOrder: 5
    }
  },
  {
    dataTableID: "academies",
    tooltip: "Academies related to the Company Users",
    label: "Academies",
    columnType: "string",
    create: {
      displayOrder: 5
    },
    table: {
      displayOrder: 6,
      columnType: "array[map]",
      display: ["name"],
      onHoverDisplay: "*",
      displaySeprator: ", "
    },
    filter: {
      displayOrder: 6,
      columnType: "selectWithCustomQuery",
      dropdown: {
        items: [{
          label: '-----',
          value: 'all'
        },
        {
          label: 'With Academies',
          value: 'with_academies'
        },
        {
          label: 'Without Academies',
          value: 'without_academies'
        }
        ],
      },
      filterQueries: {
        all: {
          $where: {}
        },
        with_academies: {
          $where: {
            academies: {
              $not_equal: []
            }
          }
        },
        without_academies: {
          $where: {
            academies: []
          }
        },
      }
    }
  },
  {
    dataTableID: "programme_periods",
    tooltip: "Programme Periods related to the Growth Goal",
    label: "Programme Periods",
    columnType: "string",
    create: {
      displayOrder: 6
    },
    table: {
      displayOrder: 7,
      columnType: "array[map]",
      display: ["programme_period_id", "programme_name"],
      onHoverDisplay: "*",
      displaySeprator: ", "
    },
    filter: {
      displayOrder: 7,
      columnType: "selectWithCustomQuery",
      dropdown: {
        items: [{
          label: '-----',
          value: 'all'
        },
        {
          label: 'With Programme Periods',
          value: 'with_programme_periods'
        },
        {
          label: 'Without Programme Periods',
          value: 'without_programme_periods'
        }
        ],
      },
      filterQueries: {
        all: {
          $where: {}
        },
        with_programme_periods: {
          $where: {
            programme_periods: {
              $not_equal: []
            }
          }
        },
        without_programme_periods: {
          $where: {
            programme_periods: []
          }
        },
      }
    }
  },
  {
    dataTableID: "user_roles",
    tooltip: "Different user roles count",
    label: "Users(role)",
    columnType: "string",
    create: {
      displayOrder: 7
    },
    table: {
      displayOrder: 8
    }
  },
  {
    dataTableID: "last_activity_date",
    tooltip: "Latest activity by the user",
    label: "Last Activity Date",
    columnType: "date",
    create: {
      displayOrder: 8
    },
    table: {
      displayOrder: 9
    },
    filter: {
      displayOrder: 9,
      columnType: "dateRange"
    }
  },
  {
    dataTableID: "growth_goal_percent",
    tooltip: "Percentage growth goal value",
    label: "Percentage Growth Goal",
    columnType: "integer",
    create: {
      displayOrder: 9
    },
    table: {
      displayOrder: 10
    },
    filter: {
      displayOrder: 10,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "growth_goal_value",
    tooltip: "Growth goal value",
    label: "Growth Goal Value",
    columnType: "integer",
    create: {
      displayOrder: 10
    },
    table: {
      displayOrder: 11
    },
    filter: {
      displayOrder: 11,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "actual_monthly_rev_recorded",
    tooltip: "Recorded monthly revenue",
    label: "Monthly Revenue Recorded",
    columnType: "string",
    create: {
      displayOrder: 11
    },
    table: {
      displayOrder: 12
    },
    filter: {
      displayOrder: 12
    }
  },
  {

    dataTableID: "target_achieved",
    tooltip: "Achieved target of company",
    label: "Target Achieved",
    columnType: "number",
    create: {
      displayOrder: 12
    },
    table: {
      displayOrder: 13
    },
    filter: {
      chipTitle: "target_achieved = $input",
      displayOrder: 13,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: 'company_summary_reports/by',
        fetchQuery: {
          $distinct: "target_achieved",
          $where: {
            target_achieved: {
              $ilike: "%$input%"
            }
          }
        },
        label: ['target_achieved'],
        value: 'target_achieved',
        labelSeperator: '',
      },
      filterQuery: {
        $where: {
          target_achieved: '$input'
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "company_data_validated",
    tooltip: "Whether company's data is validated or not",
    label: "Company Data Validated",
    columnType: "string",
    create: {
      displayOrder: 13
    },
    table: {
      displayOrder: 14
    },
    filter: {
      displayOrder: 14,
      columnType: "selectWithStaticValues",
    dropdown: {
      items: [
         'N',
         'Y'
      ]
    },
    filterQuery: {
        $where: {
          company_data_validated: '$input'
        }
      
    },
    selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "baseline_data_validated",
    tooltip: "Whether baseline's data is validated or not",
    label: "Baseline Data Validated",
    columnType: "string",
    create: {
      displayOrder: 14
    },
    table: {
      displayOrder: 15
    },
    filter: {
      chipTitle: "baseline_data_validated = $input",
      displayOrder: 15,
      columnType: "selectWithStaticValues",
      dropdown: {
        items: [
            'N',
            'Y'
        ]
      },
      filterQuery: {
        $where: {
          baseline_data_validated: '$input'
        } 
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "profile_data",
    tooltip: "Information regarding the company",
    label: "Profile Data",
    columnType: "string",
    create: {
      displayOrder: 15
    },
    table: {
      displayOrder: 16,
      columnType: "map",
      display: ["company_id", "company_name"],
      onHoverDisplay: "*",
      displaySeprator: "| "
    },
    filter: {
      displayOrder: 16
    }
  },
  // {
  //   dataTableID: "baseline_data",
  //   tooltip: "Information regarding the company's baseline",
  //   label: "Baseline Data",
  //   columnType: "string",
  //   create: {
  //     displayOrder: 16
  //   },
  //   table: {
  //     displayOrder: 17,
  //     columnType: "map",
  //     display: ["company_id", "user_id", "revenue"],
  //     onHoverDisplay: "*",
  //     displaySeprator: "| "
  //   },
  //   filter: {
  //     displayOrder: 17
  //   }
  // },
  {
    dataTableID: "monthwise_details",
    tooltip: "Monthwise detailings of the Company",
    label: "Monthwise Details",
    columnType: "string",
    create: {
      displayOrder: 17
    },
    table: {
      displayOrder: 18,
      columnType: "array[map]",
      display: ["goal_target", "month"],
      onHoverDisplay: "*",
      displaySeprator: ", "
    },
    filter: {
      displayOrder: 18
    }
  },
  {
    dataTableID: "login_count",
    tooltip: "Login count of the company user",
    label: "Login Count",
    columnType: "integer",
    create: {
      displayOrder: 18
    },
    table: {
      displayOrder: 19
    },
    filter: {
      displayOrder: 19,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "last_login_date",
    tooltip: "Last login date of any user",
    label: "Last Login Date",
    columnType: "date",
    create: {
      displayOrder: 19
    },
    table: {
      displayOrder: 20
    },
    filter: {
      displayOrder: 20,
      columnType: "dateTimeRange"
    }
  },
  // {
  //   dataTableID: "head_count",
  //   tooltip: "Count of the users that belong of the company",
  //   label: "Head Count",
  //   columnType: "integer",
  //   create: {
  //     displayOrder: 20
  //   },
  //   table: {
  //     displayOrder: 21
  //   },
  //   filter: {
  //     displayOrder: 21,
  //     columnType: "integerRange"
  //   }
  // },
  {
    dataTableID: "year",
    tooltip: "For which year??",
    label: "Year",
    columnType: "string",
    create: {
      displayOrder: 21
    },
    table: {
      displayOrder: 22
    },
    filter: {
      displayOrder: 22
    }
  },
  {
    dataTableID: "inserted_at",
    tooltip: "When was this record created?",
    label: "Inserted At",
    columnType: "date",
    create: {
      displayOrder: 22
    },
    table: {
      displayOrder: 23
    },
    filter: {
      displayOrder: 23,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "updated_at",
    tooltip: "When was this record updated?",
    label: "Updated At",
    columnType: "date",
    create: {
      displayOrder: 23
    },
    table: {
      displayOrder: 24
    },
    filter: {
      displayOrder: 24,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "last_processed_at",
    tooltip: "When was this record last processed?",
    label: "Last Processed At",
    columnType: "date",
    table: {
      displayOrder: 25
    },
    filter: {
      displayOrder: 25,
      columnType: "dateTimeRange"
    }
  }
];

const entity = {
  title: "Business Summary Reports",
  actions: {
    sort: {
      endPoint: "company_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "company_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "company_summary_reports"
    },
    filter: {
      endPoint: "company_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "company_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

const tabs = [
  {
    view: "detailView",
    entity: "events",
    endPoint: "company_summary_reports/by",
    title: "Details",
    queryFilter: {
      id: ":id"
    }
  }
];

export { sidebar, entity, keys, tabs };

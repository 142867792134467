import getcsvFileKeys from "./entity.utils.js"
const sidebar = false;


const keys = [{
  dataTableID: "toolkit_id",
  label: "Toolkit ID",
  tooltip: "Toolkit ID of this report",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    //chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "toolkit_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            toolkit_id: {
              $ilike: "%$input%"
            },
            title: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['toolkit_id', 'toolkit_name'],
      value: 'toolkit_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        toolkit_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "toolkit_name",
  tooltip: "Name of the Toolkit",
  label: "Toolkit Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
},
{
  dataTableID: "toolkit_description",
  tooltip: "Description of the respective Toolkit",
  label: "Toolkit Description",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3
  }
},
{
  dataTableID: "courses_count",
  tooltip: "Total number of courses",
  label: "Courses Count",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    columnType: "integerRange"
  }
},
{
  dataTableID: "created_by",
  tooltip: "Who created this toolkit?",
  label: "Created By",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    displayOrder: 5
  }
},
{
  dataTableID: "toolkit_created_at",
  tooltip: "When was this toolkit created?",
  label: "Toolkit Created At",
  columnType: "date",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "toolkit_last_accessed",
  tooltip: "Who accessed this toolkit recently?",
  label: "Toolkit Last Accessed",
  columnType: "date",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "dateTimeRange",

  }
},
{
  dataTableID: "label",
  tooltip: "Label of the toolkit",
  label: "Label",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 8
  }
},
{
  dataTableID: "academies_count",
  tooltip: "Number of academies",
  label: "Academies Count",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_count",
  tooltip: "Number of tools",
  label: "Tools Count",
  columnType: "number",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkit_viewed_count",
  tooltip: "Toolkit's viewed count",
  label: "Toolkit Viewed Count",
  columnType: "number",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_downloaded_in_toolkit_count",
  tooltip: "No of Tools downloaded from this toolkit",
  label: "Tools Downloaded in Toolkit Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Likes count in this toolkit",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Shares count in this toolkit",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "dateTimeRange"
  }
},

{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "toolkits",
  endPoint: "toolkits/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Toolkit Summary Reports",
  name: "toolkit_summary_reports",
  actions: {
    sort: {
      endPoint: "toolkit_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "toolkit_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "toolkit_summary_reports"
    },
    filter: {
      endPoint: "toolkit_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "toolkit_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "toolkit_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};

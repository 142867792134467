import getcsvFileKeys from "./entity.utils.js"
const sidebar = false;


const keys = [{
  dataTableID: "user_id",
  tooltip: "User ID of this report",
  label: "User ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "user_profile_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            user_id: {
              $ilike: "%$input%"
            },
            user_name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['user_id', 'user_name'],
      value: 'user_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        user_id: {
          $ilike: '%$input%'
        }
      },
      selectedFilterInput: '$input'
    },
  }
},
{
  dataTableID: "profile_id",
  tooltip: "Profile ID of the user",
  label: "Profile ID",
  columnType: "number",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    displayOrder: 2,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "user_profile_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            profile_id: {
              $ilike: "%$input%"
            },
            user_name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['profile_id', 'user_name'],
      value: 'profile_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        profile_id: {
          $ilike: '%$input%'
        }
      },
      selectedFilterInput: '$input'
    },
  }
},
{
  dataTableID: "email",
  tooltip: "Email of the user",
  label: "Email",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3
  }
},
{
  dataTableID: "last_name",
  tooltip: "Last name of the user",
  label: "Last Name",
  columnType: "string",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4
  }
},
{
  dataTableID: "user_name",
  tooltip: "Username of the user",
  label: "User Name",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  }
},
{
  dataTableID: "user_registered_at",
  tooltip: "When was this user registered?",
  label: "User Registered At",
  columnType: "date",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_login",
  tooltip: "Last login time of this user",
  label: "Last login",
  columnType: "date",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "profile_link",
  tooltip: "Profile link of the user",
  label: "Profile Link",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    link: {
      open_in_new_tab: true
    },
    displayOrder: 8
  },
  filter: {
    displayOrder: 8
  }
},
{
  dataTableID: "connections",
  tooltip: "Number of connections of the user",
  label: "Connections",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "country",
  tooltip: "Country of the user",
  label: "Country",
  columnType: "string",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10
  }
},
{
  dataTableID: "phone_number",
  tooltip: "Phone no of the user",
  label: "Phone Number",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11
  }
},
{
  dataTableID: "job_matching",
  tooltip: "Whether the job of the user matches or not?",
  label: "Job Matching",
  columnType: "boolean",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    chipTitle: "job_matching = $input",
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [
        {
          label: 'True',
          value: true
        },
        {
          label: 'False',
          value: false
        }
      ]
    },
    filterQueries: {
      true: {
        $where: {
          'job_matching': true
        }
      },
      false: {
        $where: {
          'job_matching': false
        }
      }
    }
  },
},
{
  dataTableID: "biography",
  tooltip: "Biography of the user",
  label: "Biography",
  columnType: "string",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Biography',
        value: 'with_biography'
      },
      {
        label: 'Without Biography',
        value: 'without_biography'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_biography: {
        $where: {
          biography: {
            $not_equal: ''
          }
        }
      },
      without_biography: {
        $where: {
          biography: ''
        }
      },
    }
  }
},
{
  dataTableID: "jobs",
  tooltip: "Jobs regarding user profile",
  label: "Jobs",
  columnType: "string",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Jobs',
        value: 'with_jobs'
      },
      {
        label: 'Without Jobs',
        value: 'without_jobs'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_jobs: {
        $where: {
          jobs: {
            $not_equal: []
          }
        }
      },
      without_jobs: {
        $where: {
          jobs: []
        }
      },
    }
  }
},
{
  dataTableID: "education",
  tooltip: "Education of the user",
  label: "Education",
  columnType: "string",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 14,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Education',
        value: 'with_education'
      },
      {
        label: 'Without Education',
        value: 'without_education'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_education: {
        $where: {
          education: {
            $not_equal: []
          }
        }
      },
      without_education: {
        $where: {
          education: []
        }
      },
    }
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  reate: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 17,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "tools",
  endPoint: "tools/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "User Profile Summary Reports",
  name: "user_profile_summary_reports",
  actions: {
    sort: {
      endPoint: "user_profile_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "user_profile_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "user_profile_summary_reports"
    },
    filter: {
      endPoint: "user_profile_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "user_profile_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "user_profile_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};

import getcsvFileKeys from "./entity.utils.js"
const sidebar = false;


const keys = [{
  dataTableID: "user_id",
  tooltip: "User ID of this report",
  label: "User ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "user_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            user_id: {
              $ilike: "%$input%"
            },
            user_name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['user_id', 'user_name'],
      value: 'user_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      query: {
        $where: {
          user_id: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    },
  }
},
{
  dataTableID: "user_name",
  tooltip: "Username of this user",
  label: "User Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "phone_number",
  tooltip: "Phone no of the user",
  label: "Phone Number",
  columnType: "number",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3
  }
},
{
  dataTableID: "is_dormant",
  tooltip: "Whether the academy is dormant or not?",
  label: "Is Dormant",
  columnType: "boolean",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    chipTitle: "is_dormant = $input",
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [
        {
          label: 'True',
          value: true
        },
        {
          label: 'False',
          value: false
        }
      ]
    },
    filterQueries: {
      true: {
        $where: {
          'is_dormant': true
        }
      },
      false: {
        $where: {
          'is_dormant': false
        }
      }
    }
  },
},
{
  dataTableID: "company",
  tooltip: "Name of this user's company",
  label: "Company",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    displayOrder: 5
  }
},
{
  dataTableID: "email",
  tooltip: "Email of the user",
  label: "Email",
  columnType: "string",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6
  }
},
{
  dataTableID: "gender",
  tooltip: "Gender of this user",
  label: "Gender",
  columnType: "string",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7
  }
},
{
  dataTableID: "first_name",
  tooltip: "First name of the user",
  label: "First Name",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 8,
  }
},
{
  dataTableID: "last_name",
  tooltip: "Last name of the user",
  label: "Last name",
  columnType: "string",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
  }
},
{
  dataTableID: "status",
  tooltip: "Status of the academy",
  label: "Status",
  columnType: "string",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10,
    columnType: "selectWithStaticValues",
    dropdown: {
      items: [
        'Non-Member',
        'Business Member'
      ]
    },
    filterQuery: {
      $where: {
        status: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "group_label",
  tooltip: "Group label of the user",
  label: "Group Label",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11
  }
},
{
  dataTableID: "user_registered_at",
  tooltip: "When was this user registered?",
  label: "User Registered At",
  columnType: "date",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_login_at",
  tooltip: "Last login time of this user",
  label: "Last Login At",
  columnType: "date",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "renewal_date",
  tooltip: "User's renewal date",
  label: "Renewal Date",
  columnType: "date",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "dateRange"
  }
},
{
  dataTableID: "enrollments_count",
  tooltip: "Number of enrollments of this user",
  label: "Enrollments Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_completed_count",
  tooltip: "Completed enrollments count",
  label: "Enrollments Completed Count",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "courses_taken_titles",
  tooltip: "Titles of the taken courses",
  label: "Courses Taken Titles",
  columnType: "string",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17,
    columnType: "array[string]"
  },
  filter: {
    displayOrder: 17,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Courses Taken Titles',
        value: 'with_courses_taken_titles'
      },
      {
        label: 'Without Courses Taken Titles',
        value: 'without_courses_taken_titles'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_courses_taken_titles: {
        $where: {
          courses_taken_titles: {
            $not_equal: []
          }
        }
      },
      without_courses_taken_titles: {
        $where: {
          courses_taken_titles: []
        }
      },
    }
  }
},
{
  dataTableID: "academies_titles",
  tooltip: "Titles of the academies",
  label: "Academies Titles",
  columnType: "string",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18,
    columnType: "array[string]"
  },
  filter: {
    displayOrder: 18,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Academies Titles',
        value: 'with_academies_titles'
      },
      {
        label: 'Without Academies Titles',
        value: 'without_academies_titles'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_academies_titles: {
        $where: {
          academies_titles: {
            $not_equal: []
          }
        }
      },
      without_academies_titles: {
        $where: {
          academies_titles: []
        }
      },
    }
  }
},
{
  dataTableID: "academies_count",
  tooltip: "Number of academies",
  label: "Academies Count",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "resources_downloaded_count",
  tooltip: "Number of resources downloaded",
  label: "Resources Downloaded Count",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_downloaded_count",
  tooltip: "Number of tools downloaded",
  label: "Tools Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21
  },
  filter: {
    displayOrder: 21,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkits_viewed_count",
  tooltip: "Toolkits viewed count of this user",
  label: "Toolkits Viewed Count",
  columnType: "number",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22
  },
  filter: {
    displayOrder: 22,
    columnType: "integerRange"
  }
},
{
  dataTableID: "sign_in_count",
  tooltip: "Number of sign ins",
  label: "Sign in Count",
  columnType: "number",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23
  },
  filter: {
    displayOrder: 23,
    columnType: "integerRange"
  }
},
{
  dataTableID: "accountibility_partner_email",
  tooltip: "Email of the accountability partner",
  label: "Accountibility Partner Email",
  columnType: "number",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 24
  }
},
{
  dataTableID: "accountibility_partner_name",
  tooltip: "Name of the accountability partner",
  label: "Accountibility Partner Name",
  create: {
    displayOrder: 24
  },
  table: {
    displayOrder: 25
  },
  filter: {
    displayOrder: 25,
    columnType: "string"
  }
},
{
  dataTableID: "competency",
  tooltip: "User's Competency",
  label: "Competency",
  columnType: "string",
  create: {
    displayOrder: 25
  },
  table: {
    displayOrder: 26
  }
},
{
  dataTableID: "pdp_status",
  tooltip: "User PDP status",
  label: "PDP Status",
  columnType: "number",
  create: {
    displayOrder: 26
  },
  table: {
    displayOrder: 27
  },
  filter: {
    displayOrder: 27
  }
},
{
  dataTableID: "goal_set",
  tooltip: "Number of Set goals",
  label: "Goal Set",
  columnType: "number",
  create: {
    displayOrder: 27
  },
  table: {
    displayOrder: 28
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 28
  }
},
{
  dataTableID: "goal_approved",
  tooltip: "Number of approved goals",
  label: "Goal Approved",
  columnType: "number",
  create: {
    displayOrder: 28
  },
  table: {
    displayOrder: 29
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 29
  }
},

{
  dataTableID: "posts_count",
  tooltip: "Number of posts",
  label: "Posts Count",
  columnType: "number",
  create: {
    displayOrder: 29
  },
  table: {
    displayOrder: 30
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 30
  }
},
{
  dataTableID: "comments_count",
  tooltip: "Number of comments",
  label: "Comments Count",
  columnType: "number",
  create: {
    displayOrder: 30
  },
  table: {
    displayOrder: 31
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 31
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Likes count",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 31
  },
  table: {
    displayOrder: 32
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 32
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Count of shares",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 32
  },
  table: {
    displayOrder: 33
  },
  filter: {
    columnType: "integerRange",
    displayOrder: 33
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted at",
  columnType: "date",
  create: {
    displayOrder: 33
  },
  table: {
    displayOrder: 34
  },
  filter: {
    displayOrder: 34,
    columnType: "dateRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 34
  },
  table: {
    displayOrder: 35
  },
  filter: {
    displayOrder: 35,
    columnType: "dateRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "tools",
  endPoint: "tools/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "User Summary Reports",
  name: "user_summary_reports",
  actions: {
    sort: {
      endPoint: "user_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "user_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "user_summary_reports"
    },
    filter: {
      endPoint: "user_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "user_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "user_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};

<template>
  <v-card>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent>
      <v-list-item class="px-2 mt-2">
        <v-list-item to="/" class="text-decoration-none">
          <v-list-item-content>
            <v-list-item-title class="title text-center ">
              Admin Panel
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item
          link
          v-for="(item, i) in sideBarList"
          :key="i"
          :to="item.to"
          class="text-decoration-none"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.text"> </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import getSideBarItems from "./_nav";
import {mapState} from 'vuex'
export default {
  name: "AppSidebar",
  data: () => ({
    sideBarList:'',
    drawer: false,
    mini: false
  }),
  computed:{
    ...mapState('auth',['isAdmin'])
  },
  watch:{
    isAdmin:{
      handler(){
        this.sideBarList=getSideBarItems(this.isAdmin)
      }
    }
  },
  async created(){
    this.sideBarList=await getSideBarItems(this.isAdmin)
  }
};
</script>
import getcsvFileKeys from "./entity.utils.js"
const sidebar = false;


const keys = [{
  dataTableID: "tool_id",
  tooltip: "Tool ID of this report",
  label: "Tool ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    //chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "tool_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            tool_id: {
              $ilike: "%$input%"
            },
            title: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['tool_id', 'tool_title'],
      value: 'tool_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        tool_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tool_title",
  tooltip: "Title of the tool",
  label: "Tool Title",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    displayOrder: 2
  }
},
{
  dataTableID: "tool_description",
  tooltip: "Description of the respective tool",
  label: "Tool Description",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3
  }
},
{
  dataTableID: "type",
  tooltip: "Type of the respective tool",
  label: "Type",
  columnType: "string",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "tool_summary_reports/by",
      fetchQuery: {
        $distinct: "type",
        $where: {
          type: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['type'],
      value: 'type',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        type: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "created_by",
  tooltip: "Who created this tool?",
  label: "Created By",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    displayOrder: 5
  }
},
{
  dataTableID: "tool_created_at",
  tooltip: "When was this tool created?",
  label: "Tool Created At",
  columnType: "date",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_accessed",
  tooltip: "Who accessed this tool recently?",
  label: "Last Accessed",
  columnType: "date",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "label",
  tooltip: "Label of the tool",
  label: "Label",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 8
  }
},
{
  dataTableID: "toolkits_count",
  tooltip: "Number of toolkits",
  label: "Toolkits Count",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "academies_count",
  tooltip: "Number of academies",
  label: "Academies Count",
  columnType: "number",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tool_downloaded_count",
  tooltip: "Number of tools downloaded",
  label: "Tool Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Likes count of this tool",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Shares count of this tool",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "tools",
  endPoint: "tools/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Tool Summary Reports",
  name: "tool_summary_reports",
  actions: {
    sort: {
      endPoint: "tool_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "tool_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "tool_summary_reports"
    },
    filter: {
      endPoint: "tool_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "tool_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "tool_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
